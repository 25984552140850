import { combineLatest, fromEvent } from 'rxjs';
import { auditTime, distinctUntilKeyChanged, filter, map, startWith } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { ThemeManagerService } from '@aston/themes';
import { NavigationStart, Router } from '@angular/router';

import { CTX_HELP_CONNECTOR, ContextHelpAppConnector } from './di';

@Injectable({
	providedIn: 'root'
})
export class ContextHelpIframeService {
	iframe?: HTMLIFrameElement;
	iframeResetTimeout?: number;
	knowledgeBaseUrl = '';
	theme = 'default';
	active = false;

	get enabled() {
		return this.knowledgeBaseUrl !== String(0)
	}

	get host() {
		const url = new URL(this.knowledgeBaseUrl);
		return url.host;
	}

	helpPageLoaded$ = fromEvent<MessageEvent>(window, 'message')
		.pipe(filter(({data, origin}) => data === 'bookstack-ready' && origin?.includes(this.host)));

	update$ = combineLatest([
		this.router.events.pipe(filter(event => event instanceof NavigationStart), startWith(false), map(Boolean)),
		this.themeService.current$,
		this.app.cxtHelpBaseUrl,
	]).pipe(
		// Bug 33784: [MO] Les contenus de l'aide contextuelle sont rechargés à chaque navigation
		map(([first, theme, base]) => ({ key: `${first}-${theme}-${base}`, first, theme, base })),
		distinctUntilKeyChanged('key'),
		auditTime(500),
	);

	constructor(
		private router: Router,
		private themeService: ThemeManagerService,
		@Inject(CTX_HELP_CONNECTOR) private app: ContextHelpAppConnector,
	) {
		this.update$.subscribe(({ theme, base }) => {
			this.theme = theme;
			this.knowledgeBaseUrl = base;
			this.resetIframe()
		})
	}

	pageUrl(token: string) {
		return this.knowledgeBaseUrl + token + this.helpUrlSuffix()
	}

	show(pos: {x: number, y: number}, token: string) {
		if (!this.iframe || !this.enabled) return;
		this.iframe.style.top = pos.y + 'px';
		this.iframe.style.left = pos.x + 'px';
		this.iframe.style.display = 'block';
		this.iframe.setAttribute('src', this.pageUrl(token));
	}

	hide() {
		this.resetIframe();
	}

	helpUrlSuffix() {
		return `?theme=${this.theme}&iframe=true`
	}

	askResetIframe() {
		clearTimeout(this.iframeResetTimeout)
		// eslint-disable-next-line
		this.iframeResetTimeout = window.setTimeout((_: any) => this.resetIframe(), 500)
	}

	resetIframe() {
		if (this.iframe) document.body.removeChild(this.iframe);

		const iframe = document.createElement('iframe')
		iframe.style.border = 'none'
		iframe.style.padding = '4px'
		iframe.style.display = 'none'
		iframe.style.width = '600px'
		iframe.style.height = '400px'
		iframe.style.zIndex = '10000'
		iframe.style.position = 'absolute'
		iframe.style.boxShadow = '0px 1px 6px' + (this.theme === 'default' ? '#333' : '#eee')
		iframe.style.backgroundColor = (this.theme === 'default' ? 'white' : '#222')

		const defaultContent = `
			<style>
				.container {
					width: 70px;
					height: 70px;
					margin: 150px auto;
				}
				.spinner {
					width: 70px;
					height: 70px;
					border: 8px solid #b7d9fe;
					border-top-color: #155aa7;
					border-radius: 50%;
					animation: spin 2s linear infinite;
				}
				.spinner>div{
					box-sizing: border-box;
					width: 100%;
					height: 100%;
				}
				@keyframes spin {
					100% {
						transform: rotate(360deg);
					}
				}
			</style>
			<div class="container"><div class="spinner"></div></div>
		`;
		const blob = new Blob([defaultContent], {type: 'text/html; charset=utf-8'});
		iframe.src = URL.createObjectURL(blob);
		iframe.addEventListener('mouseout', _ => {
			this.askResetIframe()
			this.active = false;
		})
		iframe.addEventListener('mouseenter', _ => {
			clearTimeout(this.iframeResetTimeout);
			this.active = true;
		})

		document.body.appendChild(iframe)
		this.iframe = iframe;
	}
}

/* This goes with the following custom Bookstack HTML code

<script>
  var query = new URLSearchParams(location.search);
  document.documentElement.classList.toggle('dark-mode', query.get('theme') == 'dark');
  document.documentElement.classList.toggle('in-iframe', query.get('iframe') == 'true');
</script>
<style>
  .in-iframe #header,
  .in-iframe #sidebar,
  .in-iframe .tri-layout-right,
  .in-iframe .comments-container,
  .in-iframe .tri-layout-mobile-tabs,
  .in-iframe #main-content > .mb-m,
  .in-iframe .grid.third.gap-xxl
    {display: none }
  .in-iframe .content-wrap.card {
    margin: 0;
    border: none;
    box-shadow: none;
  }
  .in-iframe .tri-layout-container,
  .in-iframe .tri-layout-container .tri-layout-middle {
    padding: 0;
    margin: 0;
  }
</style>

*/
