<div class="modal-header">
	<h2>{{ title }}</h2>
	<button type="button"
		class="modal-button-close fal fa-times" 
		(click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body" [class.block-loader]="!firstPageLoaded">
	<loader-spinner *ngIf="!firstPageLoaded"/>

	<ngb-carousel *ngIf="_urls.length"
		[interval]="interval"
		[showNavigationArrows]="_urls.length > 1"
		[showNavigationIndicators]="_urls.length > 1">

		<ng-template ngbSlide *ngFor="let src of _urls">
			<iframe #iframe (error)="onIframeError(iframe, src, $event)" [src]="src"></iframe>
			<div class="carousel-caption"></div>
		</ng-template>
	</ngb-carousel>
	<div *ngIf="buttonLabel" class="mt-3 text-right">
		<button class="btn btn-secondary" (click)="buttonClick.emit($event)">{{ buttonLabel }}</button>
	</div>
</div>
