import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { ContextHelpIframeService } from './context-help-iframe.service';

@Directive({
	selector: '[ctx-help-token],[ctx-help-extract]'
})
export class ContextHelpDirective implements OnInit {
	@Input('ctx-help-token') ctxHelpToken = '';
	@Input('ctx-help-extract') extract = false;

	delay = 500;

	constructor(
		private el: ElementRef,
		private iframe: ContextHelpIframeService,
	) {}

	ngOnInit(): void {
		if (!this.iframe.enabled)
			return;

		if (this.ctxHelpToken)
			this.installListeners(this.el.nativeElement, this.ctxHelpToken);

		else if (this.extract)
			this.observeAndReplaceTexts();
	}

	installListeners(element: Node, helpToken: string) {
		let timeout: number;
		(<HTMLSpanElement> element).setAttribute('ctx-help-token', '');
		element.addEventListener('mouseenter', e => {
			clearTimeout(timeout);
			timeout = window.setTimeout(() => {
				clearTimeout(timeout);
				this.iframe.show((<MouseEvent>e), helpToken);
			}, this.delay)
		});
		element.addEventListener('mouseleave', _ => {
			clearTimeout(timeout);
			timeout = window.setTimeout(() => {
				clearTimeout(timeout);
				if (!this.iframe.active) this.iframe.hide();
			}, this.delay)
		});

	}

	observeAndReplaceTexts() {
		this.replaceTexts(this.el.nativeElement.childNodes);

		observeDOM(this.el.nativeElement, m => {
			m.forEach(record => {
				this.replaceTexts(record.addedNodes);
			})
		});
	}

	replaceTexts(nodes: NodeList) {
		Array.prototype.slice.call(nodes)
			.filter(node => node.nodeType === Node.TEXT_NODE && node.textContent.includes('::'))
			.forEach(node => {
				const child = this.createElementFromHTML(node.textContent.replace(/\(([^)]*)\)::([^ ]*)/g, '<span help-token="$2">$1</span>'));
				child.querySelectorAll('[help-token]').forEach(c => this.installListeners(c, String(c.getAttribute('help-token'))))
				node.parentNode.replaceChild(child, node)
			})
	}

	createElementFromHTML(htmlString: string): HTMLSpanElement {
		const span = document.createElement('span');
		span.innerHTML = htmlString.trim();
		return span;
	}
}

// From https://stackoverflow.com/a/14570614/1689894
const observeDOM = (function () {
	return (obj: HTMLElement, callback: MutationCallback) => {
		if (!obj || obj.nodeType !== 1) return;

		if (MutationObserver) {
			// define a new observer
			const mutationObserver = new MutationObserver(callback)

			// have the observer observe for changes in children
			mutationObserver.observe(obj, { childList: true, subtree: true })
			return mutationObserver
		}

		// browser support fallback
		else if (window.addEventListener !== undefined) {
			console.warn('[context-help] cannot use browser MutationObserver')
		}

		return null;
	}
})()