import { Injectable } from '@angular/core';
import { AppConstants } from '@aston/foundation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ContextHelpIframeService } from './context-help-iframe.service';
import { ContextHelpCarouselComponent } from './context-help-carousel/context-help-carousel.component';

@Injectable({
	providedIn: 'root'
})
export class ContextHelpCarouselService {

	constructor(
		private modalService: NgbModal,
		private ctxHelpService: ContextHelpIframeService,
	) {
		this.close();
		// eslint-disable-next-line, no-explicit-any
		this.ctxHelpService.update$.subscribe(_ => this.close())
	}

	show(tokens: string[], title = ''): ContextHelpCarouselComponent | null {
		if (!this.ctxHelpService.enabled) return null;
		const modalRef = this.modalService.open(ContextHelpCarouselComponent, {...AppConstants.DEFAULT_MODAL_OPTIONS, keyboard: true})
		const component: ContextHelpCarouselComponent = modalRef.componentInstance;
		component.urls = tokens.map(t => this.ctxHelpService.pageUrl(t))
		component.title = title
		component.ngOnChanges()

		return component;
	}

	close() {
		this.modalService.dismissAll()
	}
}
