import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbCarouselModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderSpinnerComponent } from '@aston/foundation';

import { ContextHelpDirective } from './context-help.directive';
import { ContextHelpIframeService } from './context-help-iframe.service';
import { ContextHelpCarouselComponent } from './context-help-carousel/context-help-carousel.component';

const declarations = [
	ContextHelpDirective,
	ContextHelpCarouselComponent,
]

@NgModule({
	declarations,
	exports: declarations,
	imports: [
		CommonModule,
		LoaderSpinnerComponent,
		NgbCarouselModule,
		NgbModalModule,
	],
	providers: [
		ContextHelpIframeService
	]
})
export class ContextHelpModule {}
